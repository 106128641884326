/* SupersetViewer.css */

html, body {
  height: 100%;  /* Ensure full height of the page */
  margin: 0;     /* Remove default margins */
}

.superset-container {
  width: 100%;
  height: 100vh; /* Take up full viewport height */
  position: relative;
  overflow: hidden;
}

.superset-content {
  width: 100%;
  height: 100%;
}

.superset-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Session expired overlay */
.session-expired-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.session-message-card {
  background-color: white;
  border: 1px solid #dc3545;
  border-radius: 4px;
  padding: 25px 30px;
  text-align: center;
  width: 350px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.session-message-card h3 {
  color: #dc3545;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}

.session-message-card p {
  margin-bottom: 20px;
  color: #333;
  line-height: 1.5;
}

.refresh-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
}

.refresh-button:hover {
  background-color: #0069d9;
}